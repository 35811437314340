<template>
    <div class="row">
        <div class="col-12">
            <div class="card card m-b-0">
                <!-- .chat-row -->
                <div class="chat-main-box">
                    <!-- .chat-left-panel -->
                    <div class="chat-left-aside">
                        <div class="open-panel">
                            <i class="ti-angle-right"></i>
                        </div>
                        <div class="chat-left-inner form-horizontal">
                            <div class="form-material">
                                <div class="input-group searchbox">
                                    <input type="text" class="form-control" placeholder="Pencarian..."
                                        v-model="groupUiFlag.searchTerm" @keyup.enter="loadGroup">
                                    <div class="input-group-addon">
                                        <i class="ti-search"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="form-body" v-if="!$helpers.isBlank(groupUiFlag.error)">
                                <div class="form-material form-filter">
                                    <ErrorMessage :error="groupUiFlag.error" />
                                </div>
                            </div>
                            <div id="contact-loader-indicator" class="text-center mt-4" v-bind:class="{'d-none': !groupUiFlag.isLoading}">
                                <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                            </div>
                            <div id="left-inner-scrollbar" data-scrollbar="true" tabindex="1"
                                style="overflow: hidden; outline: none;">
                                <div class="scroll-content">
                                    <ul class="chatonline style-none">
                                        <li v-for="item in groupList" :key="item.id">
                                            <router-link
                                                :to="{ name: 'group' , params: {id: encodeURIComponent(item._id)}}"
                                                v-bind:class="{ active: item.selected }">
                                                <div class="wrapp-img">
                                                    <div class="initials">
                                                        <v-icon class="text-white" small>mdi-account-multiple</v-icon>
                                                        <!-- <i class="mdi mdi-account-multiple"></i> -->
                                                    </div>
                                                </div> <span class="chat-info"> <span
                                                        class="user-name">{{ item.name }}</span>
                                                    <small class="text-default">
                                                        <div class="chat-content-thumb">{{ item.memberCount }} Anggota
                                                        </div>
                                                    </small>
                                                </span>
                                                <span v-if="group.isLoading && item.selected == true">
                                                    <i class="fa fa-circle-o-notch fa-spin"></i> 
                                                </span>
                                                <span class="account-status">
                                                    <v-icon v-if="item.status == 'ACTIVE'" title="Pengguna Aktif" color="green" small>mdi-check-circle</v-icon>
                                                    <v-icon v-if="item.status == 'INACTIVE'" title="Pengguna Aktif" color="red" small>mdi-minus-circle</v-icon>
                                                </span>
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                                <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                    <div class="scrollbar-thumb scrollbar-thumb-x"
                                        style="width: 329px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                    <div class="scrollbar-thumb scrollbar-thumb-y"
                                        style="height: 20px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- .chat-left-panel -->
                    <!-- .chat-right-panel -->
                    <div class="chat-right-aside">
                        <div class="chat-main-header">
                            <div class="headchat">
                            </div>
                        </div>
                        <div class="chat-rbox">
                            <div class="wrapp-chat-content" data-scrollbar="true" tabindex="1"
                                style="overflow: hidden; outline: none;">
                                <div class="contact-add">
                                    <v-btn class="btn-primary pull-right ml-2"
                                        v-if="$router.currentRoute.params.id" 
                                        @click="$router.push('/group')"
                                    >
                                        Buat Grup Baru
                                    </v-btn>
                                    <DeleteDialog
                                        v-if="$router.currentRoute.params.id" 
                                        class="pull-right"
                                        :name="'Hapus Grup'" 
                                        :title="'Hapus Grup'"
                                        :kind="'Grup'"
                                        @delete="deleteGroup"
                                         />


              
                                    <div class="clearfix"></div>
                                    <form class="m-t-20 form-horizontal form-material" v-if="show == 'group'">
                                        <div class="wrapp-input-contact wrapp-group">
                                            <div class="d-flex justify-content-center" v-if="group.isLoading">
                                                <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                                            </div>
                                            <div v-if="!group.isLoading">
                                                <v-row>
                                                    <label for="group_name_add" class="col-md-3 col-lg-2 col-form-label">
                                                        Nama Grup
                                                    </label>
                                                    <div class="col-md-9 col-lg-10 mt-0 pt-0">
                                                        <v-text-field class="pt-0"
                                                            id="group_name_add"
                                                            v-model="group.name"
                                                            placeholder="Masukan Nama Grup" />
                                                        <!-- <input v-model="group.name" type="text" class="form-control"
                                                            placeholder="Masukan Nama Grup"> -->
                                                    </div>
                                                </v-row>
                                                <v-row>
                                                    <label for="status_add" class="col-md-3 col-lg-2 col-form-label">Status</label>
                                                    <div class="col-md-9 col-lg-10 mt-0 pt-0">
                                                        <v-switch class="mt-0 pull-left" id="status_add" v-model="group.active" color="deep-purple accent-4" />
                                                        <label class="ml-2 m-t-5 text-muted" for="accStatus">{{ group.active ? 'Aktif' : 'Non Aktif'  }}</label>
                                                    </div>
                                                </v-row>
                                                <br>
                                                <div class="form-body" v-if="!$helpers.isBlank(group.error)">
                                                    <ErrorMessage :error="group.error" />
                                                </div>
                                                <v-row class="">
                                                    <div class="col-md-6">
                                                        <div class="text-danger text-center">
                                                            <ErrorMessage :error="groupUiFlag.error" />
                                                        </div>
                                                        <div class="input-group" v-if="group.id">
                                                            <v-text-field 
                                                                v-model="group.searchTerm"
                                                                label="Masukan ID / Nama Anggota ..." 
                                                                @keyup.enter="loadMoreGroupContactList"
                                                                :loading="group.contactIsLoading"
                                                                append-outer-icon="mdi-account-search" />
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-md-6 text-right m-t-10" v-if="group.id">
                                                        <v-btn @click="show = 'addContact'" small>
                                                            <v-icon>mdi-plus</v-icon> Tambah Anggota
                                                        </v-btn>
                                                    </div>
                                                    
                                                </v-row>
                                                
                                                <div v-if="group.id" class="mt-4">
                                                    <v-data-table :headers="headers" :items="group.contactList"
                                                        :options.sync="options" :server-items-length="totalElements"
                                                        class="elevation-1" :loading="group.contactIsLoading"
                                                        :footer-props="{ 'items-per-page-options': [5, 10, 15] }">
                                                        <template v-slot:item.action="{ item }">
                                                            <!-- <v-icon title="Ubah" dense color="primary" class="mr-2" @click="editTemplate">mdi-pencil-circle</v-icon> -->
                                                            <v-icon title="Hapus" dense color="error"
                                                                @click="removeContactList(item)"> mdi-delete-circle </v-icon>
                                                        </template>
                                                    </v-data-table>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div v-else>
                                        <div class="form-material wrapp-input-contact m-t-10 wrapp-group" >
                                            <div class="input-group">
                                                <v-text-field 
                                                    v-model="contactUiFlag.searchTerm"
                                                    label="Masukan ID / Nama Anggota ..." 
                                                    @keyup.enter="loadContact"
                                                    :loading="contactUiFlag.isLoading"
                                                    append-outer-icon="mdi-account-search" />
                                            </div>
                                            <div v-if="contactUiFlag.selectedContacts.length > 0">
                                                <span class="badge badge-dark mr-1" v-for="item in contactUiFlag.selectedContacts" :key="item.id">
                                                    {{ item.name }}, 
                                                </span>
                                            </div>
                                            <br>
                                            <div class="scroll-content">
                                                <ul class="chatonline user-list-for-group">
                                                    <li v-for="(item, index) in contactList" :key="('contact' + index)">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                {{ item.name }}
                                                                <br>
                                                                <small class="text-muted">{{ item.primaryPhoneNumber }}</small>
                                                            </div>
                                                            <div class="col-md-6 text-right">
                                                                <input style="cursor:pointer" type="checkbox" v-model="contactUiFlag.selectedContacts"
                                                                    :value="item">
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li id="contact-lookup-loader-indicator" v-if="contactUiFlag.isLoading" class="text-center">
                                                         <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                                <div class="scrollbar-thumb scrollbar-thumb-x"
                                                    style="width: 329px; transform: translate3d(0px, 0px, 0px);"></div>
                                            </div>
                                            <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                                <div class="scrollbar-thumb scrollbar-thumb-y"
                                                    style="height: 20px; transform: translate3d(0px, 0px, 0px);"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ml-5 mt-2 button-wrapp" v-if="show == 'group'">
                                    <div class="" v-if="group.id">
                                        <v-btn style="margin-right: 10px;" class="m-t-10 text-white btn btn-primary"
                                            :loading="group.isSaving" 
                                            @click="editGroup" :disabled="group.isSaving"
                                        >
                                            Simpan
                                        </v-btn>
                                    </div>
                                    <div v-else class="">
                                        <v-btn style="margin-right: 10px;" class="m-t-10 text-white btn-primary"
                                            @click="addGroup"
                                            :loading="groupUiFlag.isLoading" 
                                            :disabled="group.isLoading"
                                        >
                                            Tambah
                                        </v-btn>
                                    </div>
                            </div>
                            <div class="row ml-4 mt-2" v-if="show == 'addContact'">
                                <div class="col-sm-12">
                                    <v-btn class="m-r-10 text-white btn btn-primary"
                                        @click="addContactList"
                                        :disabled="groupUiFlag.isSaving"
                                        :loading="groupUiFlag.isSaving"
                                    >
                                        Simpan
                                    </v-btn>
                                    <v-btn @click="show = 'group'"
                                        :disabled="groupUiFlag.isSaving"
                                        :loading="groupUiFlag.isSaving"
                                    >
                                        Kembali
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- .chat-right-panel -->
                </div>
                <!-- /.chat-row -->
            </div>
        </div>

        <Alert :message="alertMessage" />

        <SuccessNotif />
    </div>
</template>

<script>
    import Scrollbar from 'smooth-scrollbar';
    import axios from "axios";
    import MainLayout from "../layouts/MainLayout"
    import ErrorMessage from '../components/ErrorMessage'
    import Alert from '../components/Alert'
    import SuccessNotif from "../components/SuccessNotif"
    import Toast from '../plugins/vuenotification'
    import DeleteDialog from '../components/DeleteDialog.vue';

    import {
        mapState
    } from 'vuex'


    const $ = require("jquery")
    // const {
    //     toFriendlyDateTime
    // } = require('../utils/date-utils');
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup');
    // var debounce = require('lodash.debounce');

    var contactScrollBar = null;
    var addContactScrollBar = null;

    const CONTACT_ROW_PER_PAGE = 25;
    const GROUP_ROW_PER_PAGE = 12;

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            })
        },
        data: function () {
            return {
                contactUiFlag: {
                    isSaving: false,
                    selectedContacts: Array(),
                    isLoading: false,
                    currentPage: 1,
                    searchTerm: String(),
                    hasMoreData: true,
                    error: Object()
                },
                groupUiFlag: {
                    selectedContacts: Array(),
                    isSaving: false,
                    isLoading: false,
                    error: Object(),
                    currentPage: 1,
                    searchTerm: String(),
                    hasMoreData: true,
                },
                group: {
                    currentPage: 1,
                    hasMoreData: true,
                    error: Object(),
                    searchTerm: String(),
                    isSaving: false,
                    isLoading: false,
                    contactIsLoading: false,
                    id: String(),
                    name: String(),
                    contactList: Array(),
                    active: true,
                },
                headers: [{
                        text: 'ID',
                        value: '_id'
                    },
                    {
                        text: 'Nama',
                        value: 'name'
                    },
                    {
                        text: 'No Hp',
                        value: 'primaryPhoneNumber'
                    },
                    {
                        text: 'Aksi',
                        value: 'action',
                        sortable: false
                    },
                ],
                options: Object(),
                totalElements: 0,
                show: 'group',
                isLoadingContact: false,
                contactList: Array(),
                groupList: Array(),
                alertMessage: Object(),
            }
        },
        watch: {
            options: {
                handler() {
                    if (this.group.id) this.loadMoreGroupContactList();
                },
                deep: true,
            },
            'group.searchTerm': async function (val) {
                if (!val) {
                    this.options.page = 1
                    if (this.group.id) await this.loadMoreGroupContactList();
                }
            },
            'groupUiFlag.searchTerm': async function (val) {
                if (!val) await this.loadGroup();
            },
            show: function (val) {
                if (val == 'group') {
                    this.contactList = []

                    this.contactUiFlag = {
                        isSaving: false,
                        selectedContacts: Array(),
                        isLoading: false,
                        currentPage: 1,
                        searchTerm: '',
                        hasMoreData: true,
                        error: {}
                    }
                } 
            },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            },
            $route: async function (to, from) {
                if (this.$helpers.isBlank(to.params.id)) {
                    this.resetToEmptyForm();
                } else if (to.params.id != from.params.id) {
                    this.selectGroup(decodeURIComponent(to.params.id));
                }
            },
        },
        methods: {
            deleteGroup: async function() {
                try {
                    console.log(this.group.id);
                    await axios.delete(process.env.VUE_APP_API_URL + '/group/' + this.group.id)

                    Toast.showToast("success", "Berhasil menghapus grup")
                    await this.loadGroup()
                    this.$router.push('/group')
                } catch (error) {
                    Toast.showToast("error", error.response.data.message)
                    console.log('delete group', error);
                }
            },
            addContactList: async function () {
                this.groupUiFlag.isSaving = true
                const data = this.contactUiFlag.selectedContacts.map(e => {
                    return {
                        contactId: e.id
                    }
                })

                try {
                    await axios.put(process.env.VUE_APP_API_URL + '/group/' + this.group.id + '/contacts', data)

                    let groupList = [...this.groupList]

                    const index = groupList.findIndex(e => e._id == this.group.id)
                    const currentMemberCount = groupList[index].memberCount ? groupList[index].memberCount : 0
                    groupList[index].memberCount = currentMemberCount + data.length
                    
                    this.groupList = groupList

                    this.show = 'group';
                } catch (error) {
                    console.log('put contact group', error);
                }
                this.groupUiFlag.isSaving = false

            },
            removeContactList: async function (item) {
                this.group.contactIsLoading = true
                const data = [{
                    contactId: item._id
                }]

                try {
                    await axios.put(process.env.VUE_APP_API_URL + '/group/' + this.group.id + '/rm-contacts', data)

                    this.groupList = this.groupList.map(e => {
                        if(e._id == this.group.id) e.memberCount = e.memberCount - 1
                        return e
                    })
                    
                    await this.loadMoreGroupContactList()
                } catch (error) {
                    console.log('put contact group', error);
                }
                this.group.contactIsLoading = false
            },
            editGroup: async function () {
                this.group.isSaving = true
                const data = {
                    name: this.group.name,
                    status: this.group.active ? 'ACTIVE' : 'INACTIVE'
                }

                try {
                    const res = await axios.put(process.env.VUE_APP_API_URL + '/group/' + this.group.id, data)

                    const index = this.groupList.findIndex(e => e._id == res.data._id)
                    this.groupList.splice(index, 1, res.data)
                } catch (error) {
                    console.log("edit group", error);
                }
                this.group.isSaving = false
            },
            addGroup: async function () {
                this.groupUiFlag.isLoading = true
                const data = {
                    name: this.group.name,
                    status: this.group.active ? 'ACTIVE' : 'INACTIVE'
                }

                try {
                    const res = await axios.post(process.env.VUE_APP_API_URL + '/group/', data);

                    this.groupList = [res.data, ...this.groupList];
                    this.group.name = String();
                    this.group.active = false;
                } catch (error) {
                    console.log("add group", error);
                    this.group.error = this.$helpers.createError(error);
                }
                this.groupUiFlag.isLoading = false
            },
            resetToEmptyForm: function () {
                this.show = 'group'
                this.group = {
                    isSaving: false,
                    isLoading: false,
                    id: String(),
                    name: String(),
                    contactList: Array(),
                    active: true,
                }

                this.contactUiFlag = {
                    selectedContacts: Array(),
                    isLoading: false,
                    currentPage: 1,
                    searchTerm: '',
                    hasMoreData: true,
                    error: {}
                }
            },
            selectGroup: async function (groupId) {
                this.resetToEmptyForm()

                this.groupUiFlag.error = {}
                if (!this.$helpers.isBlank(groupId)) {
                    this.group.isLoading = true;
                    try {
                        const {
                            data
                        } = await axios.get(process.env.VUE_APP_API_URL + '/group/' + encodeURIComponent(groupId));

                        this.group.id = data._id
                        this.group.name = data.name
                        this.group.active = data.status == 'ACTIVE' ? true : false
                        this.group.contactList = data.contacts.content
                        this.totalElements = data.contacts.page.totalElements

                    } catch (error) {
                        this.contact.error = this.$helpers.createError(error);
                    }
                    this.group.isLoading = false;
                }
            },
            refreshContact: function () {
                this.msgUiFlag.currentPage = 1
                this.loadGroup()
            },
            loadContact: async function () {
                // console.log($('#contact-list').get()[0]);
                // addContactScrollBar = Scrollbar.get($('#contact-list').get()[0])

                this.contactList = [];
                this.contactUiFlag.currentPage = 1;
                this.contactUiFlag.hasMoreData = true;
                this.contactUiFlag.isLoading = true;
                this.contactUiFlag.error = {}
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/contact/', {
                        params: {
                            page: this.contactUiFlag.currentPage,
                            term: this.contactUiFlag.searchTerm,
                            size: 25,
                            status: 'ACTIVE',
                        }
                    });

                    var jsonData = resp.data;
                    this.contactUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    this.contactList = jsonData.content;

                    addContactScrollBar.update();
                    const self = this
                    setTimeout(() => {
                        addContactScrollBar.addListener(self.addContactScrollBarListener);
                    }, 250)

                } catch (error) {
                    this.contactUiFlag.error = this.$helpers.createError(error)
                    console.log("loadContact", error);
                }
                this.contactUiFlag.isLoading = false;
            },
            loadMoreContacts: async function () {
                this.contactUiFlag.currentPage++;
                this.contactUiFlag.error = {};
                this.contactUiFlag.isLoading = true;
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/contact', {
                        params: {
                            page: this.contactUiFlag.currentPage,
                            term: this.contactUiFlag.searchTerm,
                            size: GROUP_ROW_PER_PAGE,
                            status: 'ACTIVE'
                        }
                    });
                    var jsonData = resp.data;
                    this.contactUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    var tmp = jsonData.content

                    var scrollView = Scrollbar.get($('.wrapp-chat-content').get()[0]);
                    // var currentHeight = scrollView.getSize().content.height;

                    this.contactList = [...this.contactList, ...tmp]
                    this.contactUiFlag.isLoading = false;

                    scrollView.update();
                    setTimeout(() => {
                        scrollView.setPosition(0, scrollView.offset.y);
                        addContactScrollBar.addListener(this.addContactScrollBarListener);
                    }, 120)
                } catch (error) {
                    this.contactUiFlag.isLoading = false;
                    this.group.error = this.$helpers.createError(error)
                }
            },
            addContactScrollBarListener: function (status) {
                if (this.contactUiFlag.hasMoreData && status.limit.y == status.offset.y && this.show == 'addContact') {
                    addContactScrollBar.removeListener(this.addContactScrollBarListener);
                    this.loadMoreContacts();
                }
            },
            loadGroup: async function () {
                this.groupList = [];
                this.groupUiFlag.error = {};

                this.groupUiFlag.currentPage = 1;
                this.groupUiFlag.hasMoreData = true;

                this.groupUiFlag.isLoading = true;
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/group', {
                        params: {
                            page: this.groupUiFlag.currentPage,
                            term: this.groupUiFlag.searchTerm,
                            size: GROUP_ROW_PER_PAGE,
                            // status: 'ACTIVE'
                        }
                    });
                    this.groupUiFlag.isLoading = false;
                    var jsonData = resp.data;
                    this.groupUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    this.groupList = jsonData.content
                } catch (error) {
                    this.groupUiFlag.error = this.$helpers.createError(error)
                }
                this.groupUiFlag.isLoading = false;
            },
            loadMoreGroups: async function () {
                this.groupUiFlag.currentPage++;
                this.groupUiFlag.error = {};
                this.groupUiFlag.isLoading = true;
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/group', {
                        params: {
                            page: this.groupUiFlag.currentPage,
                            term: this.groupUiFlag.searchTerm,
                            size: GROUP_ROW_PER_PAGE,
                            // status: 'ACTIVE'
                        }
                    });
                    var jsonData = resp.data;
                    this.groupUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    var tmp = jsonData.content

                    this.groupList = [...this.groupList, ...tmp]
                    this.groupUiFlag.isLoading = false;
                } catch (error) {
                    this.groupUiFlag.isLoading = false;
                    this.groupUiFlag.error = this.$helpers.createError(error)
                }
            },
            loadMoreGroupContactList: async function () {
                this.group.contactIsLoading = true
                let params = Object();
                params.term = this.group.searchTerm

                if (this.options && this.options.page) {
                    params.page = this.options.page;
                }

                if (this.options && this.options.itemsPerPage) {
                    params.size = this.options.itemsPerPage;
                }

                if (this.groupUiFlag.searchTerm) params.page = 1

                try {
                    var {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/group/' + this.group.id + '/contacts', {
                        params: params
                    });

                    this.group.contactList = data.content
                    this.totalElements = data.page.totalElements
                } catch (error) {
                    console.log(error);
                }

                this.group.contactIsLoading = false
            },
            loadInitialData: async function () {
                await this.loadGroup();
                if (this.$route.params.id != null) {
                    this.selectGroup(decodeURIComponent(this.$route.params.id));
                    
                    while(this.groupList.findIndex(e => e._id == decodeURIComponent(this.$route.params.id)) == -1) {
                        await this.loadMoreGroups() 
                    }
                }

            },
            searchContact: async function (val) {
                this.isLoadingContact = true
                try {
                    var {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/contact/', {
                        params: {
                            page: 0,
                            term: val,
                            size: CONTACT_ROW_PER_PAGE,
                        }
                    });

                    this.inputContactList = data.content
                } catch (error) {
                    console.log("search-input-contact", error);
                }
                this.isLoadingContact = false
            },
            searchInputContactWithDelay(val) {
                clearTimeout(this._timerId);
                this._timerId = setTimeout(() => this.searchContact(val), 500);
            },
        },
        created: function () {
            this.$emit('update:layout', MainLayout);
        },
        mounted: async function () {
            var self = this
            Scrollbar.initAll();
            contactScrollBar = Scrollbar.get($('#left-inner-scrollbar').get()[0]);
            addContactScrollBar = Scrollbar.get($('.wrapp-chat-content').get()[0]);

            contactScrollBar.addListener((status) => {
                if (this.groupUiFlag.hasMoreData && status.offset.y == contactScrollBar.limit.y) {
                    setTimeout(() => self.loadMoreGroups(), 500);
                }
            });
            if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
                this.loadInitialData();
            }
            try {
                mainuisetup();
                commonpagesetup("message", this.$route.params.id);
            } catch (err) {
                console.log(err);
            }
        },
        components: {
            ErrorMessage,
            Alert,
            SuccessNotif,
            DeleteDialog
        }
    }
</script>